<!--
 * @Description:系统管理 数据权限管理 dataRightsManagement
 * @Author: Li bin
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-25 16:06:11
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="路段名称">
              <el-input v-model="searchForm.parkName"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="getMenu">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <el-col :span="12">路段泊位信息管理</el-col>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="绑定角色">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="lookRole(scope.row)"
                             type="text"
                             size="medium">查看角色</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <el-dialog :title="dialogTitle"
               :visible.sync="isShowAuthority"
               append-to-body>
      <authority :parkId="parkId"
                 @confirm="authorityConfirm" />
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import authority from "./components/authority";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
    authority
  },
  data () {
    //这里存放数据
    return {
      // 表头
      tableColumnList: [
        {
          prop: 'parkId',
          label: '停车场编号',
        },
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'parkType',
          label: '停车场类型',
        },
      ],
      //
      tableList: {
        list: [],
      },
      //
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      // 筛选表单
      searchForm: {
        parkName: '',
        parkTypeCode: null,
        pageNum: 1,
        pageSize: 15
      },
      // 角色修改弹窗名称
      dialogTitle: '角色修改',
      // 角色修改弹窗是否展示
      isShowAuthority: false,
      // 角色修改的停车场ID
      parkId: ''
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getMenu()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 权限角色确认按钮
    authorityConfirm (parkId, roleCodeList) {
      if (parkId) {
        let delInfo = [{
          parkId: parkId
        }]
        this.$systemRoleManagement.delRoleAuthority(delInfo).then(res => {
          console.log("删除成功", res);
          let addInfo = []
          roleCodeList.forEach(roleItem => {
            addInfo.push({
              roleCode: roleItem,
              parkId: parkId
            })
          })
          this.$systemRoleManagement.insertRoleAuthority(addInfo).then((res) => {
            console.log("插入成功", res);
            this.isShowAuthority = false
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          }).catch(() => {
            this.$message.error('操作失败！');
          })
        })
      } else {
        console.error("parkId不存在!!!");
        this.$message.error('操作失败！');
      }
    },

    // 查看角色
    lookRole (row) {
      this.isShowAuthority = true
      this.parkId = row.parkId
    },
    //获取停车场列表
    getMenu () {
      this.$yardManagementApi
        .ParkController(this.searchForm)
        .then((response) => {
          this.tableList.list = response.resultEntity.list
          this.paginationConfig.total = response.resultEntity.total
        })
    },
    //分页器
    getList (change) {
      this.searchForm.pageSize = change.pageSize
      this.searchForm.pageNum = change.pageNum
      this.getMenu()
    },
    // 重置
    resetForm () {
      this.searchForm.parkName = ''
      this.searchForm.parkTypeCode = null
      this.searchForm.pageNum = 1
      this.getMenu()
    },
  },
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>